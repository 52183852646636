<template>

    <b-tabs>
  
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="FileIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">طلبات بدء الحالة  </span>
        </template>
        <request-auditing />
  
      </b-tab>
  
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="FileIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">طلبات إغلاق الحالة  </span>
        </template>
        <request-auditing-closing />
  
      </b-tab>
  
    </b-tabs>
  
  </template>
  
  <script>
  import {
    BTab, BTabs, BCard, BAlert, BLink,
  } from 'bootstrap-vue'
  import { ref, onUnmounted } from '@vue/composition-api'
  import router from '@/router'
  import store from '@/store'
  
  import RequestAuditing from './request-auditing.vue'
  import RequestAuditingClosing from './request-auditing-closing.vue'

  
  export default {
    components: {
      BTab,
      BTabs,
      BCard,
      BAlert,
      BLink,
      RequestAuditing,
      RequestAuditingClosing
    },
    setup() {
      const userData = ref(null)
  
      return {
        userData,
      }
    },
  }
  </script>
  
    <style>
  
  .position-relative.h-100.table-responsive {
      min-height: 500px !important;
  }
  </style>
  